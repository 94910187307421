.B2B {
    background-color: var(--Acento1);

    min-height: 100vh;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.B2BLogin {
    background-color: var(--Acento1);

    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.B2BLogin form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.B2BLogin form button{
    background-color: transparent;
    border-style: none;
    margin: 0;
    /* margin-top: 50px; */
    padding: 0;
    width: 100%;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* background-color: red; */

    z-index: 99;
}

.B2B .navbar {
    width: 100%;
    height: 80px;

    position: fixed;
    bottom: 0;

    display: flex;

    background-color: var(--Principal);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.B2B .navbar .navbarItem {
    width: 25%;
    height: 100%;

    border-color: var(--Oscuro);
    color: var(--Oscuro);
    /* background-color: #E4AB0A; */
    border-style: solid;
    border-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-size: 0.8rem;
}

.B2B .navbar .navbarItem .indicador {
    width: 60%;
    height: 5px;

    /* margin-left: 20px;
    margin-right: 20px; */
    margin-bottom: 10px;

    border-radius: 50px;

    background-color: transparent;
}

.B2B .navbar .navbarItem .indicador.activado {
    background-color: #E4AB0A;
}