#Entradas {
    margin-bottom: 100px;

    display: flex;
    flex-direction: column;

    padding-top: 50px;
    padding-right: 100px;
    padding-left: 100px;

    position: relative;
    z-index: 2;
}

#Entradas h2{
    width: 100%;
    text-align: center;
    color: var(--Principal);
    color: var(--Oscuro);
    font-size: 2rem;
}

#Entradas .entradasContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 100px;
}

#Entradas .entradasContainer .entrada {
    /* height: 30vw; */
    position: relative;

    padding: 50px;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: var(--Acento1);
}

#Entradas .entradasContainer .entrada:first-child {
    background-color: var(--Acento2);
}
#Entradas .entradasContainer .entrada:last-child {
    background-color: var(--Acento3);
}

#Entradas .entradasContainer .entrada h3 {
    font-size: 2rem;
}

#Entradas .entradasContainer .entrada h4 {
    font-size: 1.2rem;
}
#Entradas .entradasContainer .entrada h4 {
    margin-bottom: 0px;
}


#Entradas .entradasContainer .entrada .proximamente {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    height: 100%;
    width: 100%;

    /* background: rgba(255, 255, 255, 0.2); */
    background: hsla(0, 0%, 0%, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    display: none;
}

#Entradas .entradasContainer .entrada .proximamente div {
    font-size: 3rem;
    font-weight: 800;
    color: var(--Principal);

    transform: rotate(-45deg);
}

#Entradas .comprar {
   height: 100px;
   width: 100%;

   margin-top: 100px;

   background-color: var(--Principal);

   border-radius: 50px;
   border-style: solid;
   border-width: 4px;

   font-size: 2rem;
   font-weight: 800;
   text-decoration: none;
   color: var(--Acento3);

   display: flex;
   align-items: center;
   justify-content: center;
   justify-content: space-evenly;

   transition: all 0.5s;
}

#Entradas .comprar.disabled {
   /* background-color: #353434; */
   border-color: #353434;
   color: #353434;
}
/* #Entradas .comprar:hover {
   background-color: var(--Acento3);
   color: var(--Principal);
} */


@media (max-width: 1400px) {
    #Entradas .entradasContainer {
        gap: 50px;
    }
    #Entradas .entradasContainer .entrada {
        padding: 25px;
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 500px) {
    #Entradas {
        padding-right: 25px;
        padding-left: 25px;
    }
    
    #Entradas .entradasContainer {
        flex-direction: column;
        gap: 50px;
    }

    #Entradas .comprar {
        margin-top: 50px;
        font-size: 1.5rem;
        flex-direction: column;

        height:fit-content;
     }
}