.Lista {
    /* background-color: red; */

    /* height: 100%; */
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 25px;

    overflow: scroll;

    padding-top: 100px;
    padding-bottom: 100px;
}

.Lista .usuario {
    height: 100px;
    width: 90%;

    position: relative;

    background-color: var(--Principal);

    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.2); */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    border-style: solid;
    border-width: 3px;
    border-color: var(--Principal);
}
.Lista .usuario.like{
    border-color: #B3E41D;
}
.Lista .usuario.false{
    border-color: #EE4125;
}
.Lista .usuario h1{
 text-align: center;
 font-size: 1.6rem;
}

.Lista .usuario .fotocontainer{
   height: 80px;
   width: 80px;

   margin-left: 25px;
   /* margin-right: 70px; */

   background-color: var(--Oscuro);

   border-radius: 50%;

   overflow: hidden;

   display: flex;
   align-items: center;
   justify-content: center;
}

.Lista .usuario .fotocontainer img{
    height: 80px;
    width: 80px;
    object-fit: cover
}

.Lista .usuario .data{
   height: 100%;
   width: 70%;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   text-align: center;
}