.Home {
    width: 100%;
    height: 100vh;

    padding-top: 100px;

    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    overflow: hidden;
}

.Home .cardContainer {
    height: 500px;
    width: 380px;
}


.swipe {
    position: absolute;
}

.buttons {
    width: 90%;
    margin-top: 50px;

    display: flex;
    justify-content: space-between;
}

.buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-style: none;
    color: var(--Oscuro);
    font-size: 18px;
    background-color: var(--Principal);
    transition: 200ms;
    font-weight: bolder;
    width: 160px;

    height: 60px;

    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.buttons button:hover {
    transform: scale(1.05);
}