#Actividades {
    padding-bottom: 200px;
    padding-top: 50px;

    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#Actividades h2 {
    width: 100%;

    /* color: var(--Principal); */
    color: var(--Oscuro);
    font-size: 2rem;
    text-align: center;
}

#Actividades .Lista {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 250px;
    gap: 50px;

    /* padding-left: 10px; */

}

#Actividades .Lista .actividad {
    /* height: 300px; */
    width: 900px;

    /* position: relative; */
    z-index: 2;

    display: flex;
    /* flex-direction: column; */
    align-items: center;

    gap: 20px;

    /* background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px; */
}

#Actividades .Lista .actividad .imgcontainer {
    height: 250px;
    width: 250px;
    /* height: 100%;
    width: auto; */

    margin-left: 50px;

    border-radius: 50%;
    /* border-radius: 50px; */

    overflow: hidden;
}

#Actividades .Lista .actividad img {
    height: 100%;
    width: auto;
}

#Actividades .Lista .actividad .descripcion {
    height: 250px;
    /* width: 400px; */
    /* width: 250px; */

    padding: 50px;

    border-radius: 50px;

    color: var(--Principal);
    color: var(--Oscuro);


    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3); */

    /* position: absolute;
    bottom: -150px;
    left: -150px; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#Actividades .Lista .actividad .descripcion a.button,
#Actividades .Lista .actividad .descripcion a.button .texto, 
#Actividades .Lista .actividad .descripcion a.button .sombra{
    height: 30px;
    width: 90%;
}
#Actividades .Lista .actividad .descripcion svg {
    height: 70px;
    width: 70px;
}

#Actividades .Lista .actividad .descripcion button {
    height: 30px;
    width: 100%;

    margin-top: 20px;
    padding: 0;

    font-weight: 800;

    background-color: var(--Acento2);
    color: var(--Oscuro);

    border-style: hidden;
    border-radius: 50px;
}

#Actividades .Lista .actividad:first-child .descripcion h3 {
    margin-bottom: 10px;
}

#Actividades .Lista .actividad:first-child .descripcion button {
    margin-top: 5px;
}

#Actividades .backgraundContainer {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    overflow: hidden;
}

#Actividades .background {
    position: absolute;

    width: 1000px;
    height: auto;

    opacity: 10%;

    z-index: 1;

    pointer-events: none;
}

#Actividades .background .cls-1 {
    fill: black;
    fill: var(--Oscuro);
}

#Actividades .background.uno {
    top: -300px;
    left: -400px;

    transform: rotate(20deg);
}

#Actividades .background.dos {
    top: 300px;
    left: 500px;

    transform: rotate(-20deg);
}

#Actividades .background.tres {
    top: 1000px;
    left: -200px;

    transform: rotate(50deg);
}

#Actividades .background.cuatro {
    top: -100px;
    right: -500px;
}


@media (max-width: 1400px) {
    #Actividades .Lista {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        /* gap: 200px; */
    }

    #Actividades .Lista .actividad .descripcion {
        padding: 25px;

        bottom: -100px;
        left: -100px;
    }

    #Actividades .Lista .actividad .descripcion svg {
        height: 50px;
        width: 50px;
    }
}

@media (max-width: 500px) {
    #Actividades {
        padding-bottom: 0;
    }
    #Actividades .Lista {
        justify-content: flex-end;
        gap: 150px;

        padding-right: 10px;
    }

    #Actividades .Lista .actividad {
        flex-direction: column;
    }

    #Actividades .background {
        width: 600px;
        height: auto;
    }

    #Actividades .background.uno {
        display: none;
    }

    #Actividades .background.dos {
        left: 0;
    }

    #Actividades .background.cuatro {
        top: 2000px;
        left: -50px;
    }


}