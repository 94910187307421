.Reuniones {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 25px;

    overflow: scroll;
}

.Reuniones .reunion {
    height: 200px;
    width: 90%;

    position: relative;

    background-color: var(--Principal);

    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    overflow: hidden;
}

.Reuniones .reunion .horario {
    width: 100%;
    height: 40px;

    background-color: var(--Oscuro);
    color: var(--Principal);
    font-size: 1.3rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.Reuniones .reunion .conQuien {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

    display: flex;
    justify-content: space-between;
}

.Reuniones .reunion .conQuien div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    color: var(--Oscuro);
    font-size: 1.1rem;
}
.Reuniones .reunion .conQuien svg{
    height: 30px;
    width: 30px;
}
.Reuniones .reunion .contacto{
   width: 100%;
   height: 30px;
   margin-top: 20px;

   display: flex;
   justify-content: center;
   align-items: center;

   color: var(--Oscuro);
   text-decoration: none;
   font-size: 1.2rem;
}

.Reuniones .reunion .contacto svg{
    height: 30px;
    width: 30px;
    margin-right: 25px;
}