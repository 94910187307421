@import url("https://use.typekit.net/lyn4lpb.css");

:root {
  --Principal: #f3f5ee;
  --Oscuro: #2a3430;
  --Acento1: #95B141;
  --Acento2: #d5b58c;
  --Acento3: #048BA8;
  /* --Acento3: #d5b58c; */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "acumin-pro", sans-serif;

  /* background-color: var(--Oscuro); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TexturaFondoGeneral {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  height: 100%;
  width: 100%;

  mix-blend-mode: hard-light;
  mix-blend-mode: multiply;
  opacity: 30%;
  opacity: 10%;

  pointer-events: none;

  /* display: none; */
}

.instagram{
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 10000;
  
  height: 70px;
  width: 70px;

  border-radius: 50%;

  background-color: var(--Acento1);

  display: flex;
  align-items: center;
  justify-content: center;
}

.instagram svg{
  height: 80%;
  width: auto;

  color: var(--Oscuro);
}