#TextoIntermedio{
    height: 200px;

    background-color: var(--Acento2);
    color: var(--Principal);
    font-size: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center
}

#TextoIntermedio p{
    width: 80%;
}

@media (max-width: 500px) {
    #TextoIntermedio{
        height: 250px;
        font-size: 1rem;
    }
}