.ResponsiveAppBar {
    position: fixed !important;
    top: 0;
    z-index: 500;

    margin-top: 10px;

    background-color: transparent !important;

    box-shadow: none !important;

    display: flex;
}
.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root{
    color: var(--Oscuro) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root a{
    text-decoration: none;
    color: black;
}

.ResponsiveAppBar a.button, .ResponsiveAppBar a.button .texto, .ResponsiveAppBar a.button .sombra{
    height: 30px;
}

.ResponsiveAppBar a.button {
    margin-left: 30px;
}

.ResponsiveAppBar img {
    height: 40px;
    width: auto;
}

@media (min-width: 900px) {
    .css-1t6c9ts {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row-reverse;
    }
}

@media (min-width: 0px) {
    .css-1lvtzne {
        flex-direction: row-reverse;
    }
}