@import url("https://use.typekit.net/lyn4lpb.css");
@import url("https://use.typekit.net/lyn4lpb.css");

#Header {
    /* height: 140vh; */
    width: 100%;

    position: relative;

    overflow: hidden;

    background-color: var(--Acento1);
}

#Header .fondo {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 0;

    mix-blend-mode: multiply;
    /* opacity: 50%; */
}

#Header .texto {
    height: 70%;
    width: 100%;

    margin-top: 50px;
    margin-bottom: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#Header .texto img {
    height: 230px;
    margin: 0;
    margin-bottom: 50px;

    z-index: 2;
}

#Header .texto h1 {
    margin: 0;

    color: #F2E0A3;

    font-family: "effra", sans-serif;
    font-weight: 900;
    font-style: normal;

    z-index: 2;
}

#Header .cards {
    /* height: 40%; */
    width: 100%;

    display: flex;
    justify-content: center;

    /* background-color: rgb(25, 0, 255); */
}

#Header .cards .card {
    height: 300px;
    width: 200px;

    position: relative;
    margin: 15px;

    text-align: center;
}

#Header .cards .card.baja {
    margin-top: 100px;
}

#Header .cards .card img {
    height: 300px;
    width: 200px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    border-radius: 25px;
}

#Header .cards .card h2 {
    position: relative;
    z-index: 2;
    top: -60px;

    margin-top: 10px;

    font-family: "podium-soft-variable", sans-serif;
    /* font-variation-settings: "wdth" 200; */
    font-weight: 100;

    color: var(--Oscuro);

    /* color: var(--Principal); */
    /* background-color: var(--Principal); */
}


@media (max-width: 500px) {
    #Header .texto {
        margin-top: 100px;
        /* margin-bottom: 200px; */
    }

    #Header .texto img {
        height: 80px;
        margin: 0;
        margin-bottom: 50px;
    
        z-index: 2;
    }

    #Header .texto h1 {
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
    
        color: #F2E0A3;
    
        font-family: "effra", sans-serif;
        font-weight: 900;
        font-style: normal;

        text-align: center;

        font-size: 1.5rem;
    
        z-index: 2;
    }

    #Header .cards {
        justify-content: space-around;
        flex-wrap: wrap;

        margin-bottom: 50px;
    }

    #Header .cards .card {
        margin-top: 100px;
    }

    #Header .cards .card {
        height: 250px;
        width: 150px;
    
        position: relative;
        margin: 0;
    
        text-align: center;
    }
    
    #Header .cards .card img {
        height: 250px;
        width: 150px;
    
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    
        border-radius: 25px;
    }
}