#Ubicacion{
    position: relative;
    /* height: 100vh; */

    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
}

#Ubicacion h2{
    font-size: 2rem;
    color: var(--Principal);
    color: var(--Oscuro);
}

#Ubicacion .direccion{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 50px;
    margin-bottom: 100px;

    color: var(--Principal);
    color: var(--Oscuro);
}


#Ubicacion .fondo{
    position: absolute;
    top: 0;
    left: 0;

    opacity: 20%;

    /* height: 100%; */
    height: auto;
    width: 100%;

    z-index: 1;
}

#Ubicacion .map{
    height: 500px;
    width: 100%;

    overflow: hidden;
}

@media (max-width: 500px) {
    #Ubicacion{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    #Ubicacion .direccion{
        margin-top: 0;
        margin-bottom: 50px;
    }

    #Ubicacion .fondo{
        height: 100%;
        width: auto;
    }
}