.Perfil {
    width: 100%;
    height: 60vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.Perfil .data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Perfil button {
    background-color: transparent;
    border-style: none;
}

.Perfil .data .FotoContainer{
    height: 200px;
    width: 200px;

    background-color: var(--Oscuro);

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.Perfil .data .FotoContainer img{
    width: 100%;
}